import React from 'react';
//import React, {useEffect} from 'react';
//import ReactGA from 'react-ga'
import './App.css';
const logo = require('./x-logo-820.png');

//const gaId = "";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Spice" width="281" height="100"></img>
        {/* <h1>
          Spice
        </h1> */}
         {/* <p>
          Thank you.
        </p> */}
        {/*
        <ul>
          <a href="mailto: contact@extrasalt.gg">Email Us: contact@extrasalt.gg</a>
      </ul> */}
      </header>
    </div>
  );
}

export default App;
